.app-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-loader {
    opacity: 0;
  }
  .app-loader.active {
    opacity: 100;
  }
  .app-visible {
    transition: all 0.5s ease-in-out;
  }
  .app-visible {
    opacity: 0;
  }
  .app-visible.active {
    opacity: 100;
  }