/*==== rtl css ====*/



.app-traffic-area.app-traffic-area-rtl .traffic-thumb {
	left: 0;
	right: auto;
	transform: translateY(-50%) rotateY(-180deg);
}


.app-traffic-area-rtl .app-traffic-title{
	padding-left: 220px;
	padding-right: 0;
}


.app-traffic-area-rtl .app-traffic-service .icon{
	right: 0;
	left: auto;
}



.app-faq-area-rtl .faq-accrodion .accrodion .accrodion-title::after,
.app-faq-area-rtl .faq-accrodion .accrodion .accrodion-title::before{
	right: auto;
	left: 30px;
}


.app-project-area.app-project-area-rtl .app-project-box .app-project-thumb{
	left: 70px;
	right: auto;
}



.app-project-area-rtl .app-project-content .input-box input{
	padding-left: 0;
	padding-right: 30px;
}


.app-project-area-rtl .app-project-content .input-box button{
	margin-left: 0;
	margin-right: 10px;
}





.app-header-area-rtl .app-header-main-menu ul{
	text-align: right;
}





.app-header-area-rtl .app-header-main-menu ul > li .sub-menu{
	text-align: right;
}




















