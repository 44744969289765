.app-hero-area {
    padding-top: 200px;
    padding-bottom: 110px;
    overflow: hidden;
    position: relative;
    z-index: 10;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .app-hero-area.app-hero-3-area {
    background-image: url(../../../assets/images/hero-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 180px;
    padding-bottom: 0px;
    overflow: visible;
    z-index: 15;
  }
  .app-hero-area.app-hero-3-area .app-hero-content .app-title {
    color: #fff;
    font-size: 70px;
    line-height: 80px;
    padding: 0 80px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .app-hero-area.app-hero-3-area .app-hero-content .app-title {
      font-size: 54px;
      line-height: 64px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-hero-area.app-hero-3-area .app-hero-content .app-title {
      font-size: 50px;
      line-height: 60px;
    }
  }
  @media (max-width: 767px) {
    .app-hero-area.app-hero-3-area .app-hero-content .app-title {
      font-size: 36px;
      line-height: 46px;
      padding: 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .app-hero-area.app-hero-3-area .app-hero-content .app-title {
      font-size: 46px;
      line-height: 56px;
      padding: 0;
    }
  }
  .app-hero-area.app-hero-3-area .app-hero-content p {
    color: #fff;
    opacity: 0.5;
    font-size: 18px;
    line-height: 28px;
  }
  .app-hero-area.app-hero-3-area .app-hero-content .hero-btns {
    margin-top: 35px;
  }
  .app-hero-area.app-hero-3-area .app-hero-content .hero-btns .main-btn {
    border-color: #ff6b58;
    color: #ff6b58;
    background: transparent;
    margin-right: 14px;
  }
  .app-hero-area.app-hero-3-area
    .app-hero-content
    .hero-btns
    .app-video-popup {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
  }
  @media (max-width: 767px) {
    .app-hero-area.app-hero-3-area
      .app-hero-content
      .hero-btns
      .app-video-popup {
      margin-top: 20px;
    }
  }
  .app-hero-area.app-hero-3-area
    .app-hero-content
    .hero-btns
    .app-video-popup
    i {
    height: 45px;
    width: 45px;
    text-align: center;
    line-height: 42px;
    border-radius: 50%;
    border: 2px solid #fff;
    color: #fff;
    margin-right: 10px;
  }
  .app-hero-area.app-hero-3-area .app-hero-content .thumb {
    margin-bottom: -210px;
  }
  @media (max-width: 767px) {
    .app-hero-area.app-hero-3-area .app-hero-content .thumb {
      display: none;
      margin-bottom: 0;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .app-hero-area.app-hero-3-area .app-hero-content .thumb {
      display: block;
      margin-bottom: -210px;
    }
  }
  .app-hero-area.app-hero-3-area.app-hero-5-area {
    background-color: #eef1f6;
    background-image: url(../../../assets/images/hero-bg-2.png);
    padding-top: 280px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .app-hero-area.app-hero-3-area.app-hero-5-area {
      padding-top: 120px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-hero-area.app-hero-3-area.app-hero-5-area {
      padding-top: 160px;
    }
  }
  @media (max-width: 767px) {
    .app-hero-area.app-hero-3-area.app-hero-5-area {
      padding-top: 120px;
      padding-bottom: 100px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .app-hero-area.app-hero-3-area.app-hero-5-area {
      padding-top: 120px;
      padding-bottom: 0px;
    }
  }
  .app-hero-area.app-hero-3-area.app-hero-5-area
    .app-hero-content
    .thumb {
    margin-bottom: -110px;
  }
  .app-hero-area.app-hero-3-area.app-hero-5-area
    .app-hero-content
    .app-title {
    font-weight: 700;
    font-size: 70px;
    line-height: 80px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .app-hero-area.app-hero-3-area.app-hero-5-area
      .app-hero-content
      .app-title {
      font-size: 50px;
      line-height: 60px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-hero-area.app-hero-3-area.app-hero-5-area
      .app-hero-content
      .app-title {
      font-size: 40px;
      line-height: 50px;
    }
  }
  @media (max-width: 767px) {
    .app-hero-area.app-hero-3-area.app-hero-5-area
      .app-hero-content
      .app-title {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .app-hero-area.app-hero-3-area.app-hero-5-area
     .app-hero-content
      .app-title {
      font-size: 36px;
      line-height: 46px;
    }
  }
  .app-hero-area.app-hero-3-area.app-hero-5-area
    .app-hero-content
    .hero-btns
    .app-video-popup
    i {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .app-hero-area.app-hero-3-area.app-hero-5-area
    .app-hero-content
    .hero-btns
    .main-btn {
    border-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  .app-hero-area.app-hero-3-area.app-hero-5-area
    .app-hero-content
    .hero-btns
    .main-btn:hover {
    background: #f84a6e;
    color: #fff;
  }
  .app-hero-area .hero-shape-1 {
    position: absolute;
    left: 0;
    top: 250px;
    z-index: -1;
    animation: linear 20s animationFramesOne infinite;
  }
  .app-hero-area .hero-shape-2 {
    position: absolute;
    left: 80px;
    z-index: -1;
    top: 480px;
    animation: linear 25s animationFramesThree infinite;
  }
  .app-hero-area .hero-shape-3 {
    position: absolute;
    bottom: 120px;
    right: 320px;
    z-index: -1;
    animation: linear 25s animationFramesFive infinite;
  }
  .app-hero-area.app-hero-4-area {
    background: #f9f9f9;
    background-image: url(../../../assets/images/hero-shape-1.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 6% 100%;
    padding-top: 140px;
    padding-bottom: 60px;
  }
  .app-hero-area.app-hero-6-area {
    background-image: url(../../../assets/images/hero-bg-3.jpg);
  }
  .app-hero-area.app-hero-7-area {
    background-image: url(../../../assets/images/hero-bg-4.png);
  }
  .app-hero-area.app-hero-8-area {
    position: relative;
    z-index: 10;
  }
  .app-hero-area.app-hero-8-area .home-8-shape-1 {
    position: absolute;
    left: 0;
    bottom: 100px;
    z-index: -1;
  }
  .app-hero-area.app-hero-8-area .home-8-shape-2 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
  .app-hero-area.app-hero-8-area .home-8-shape-3 {
    position: absolute;
    top: 400px;
    right: 290px;
    z-index: -1;
  }
  .app-hero-area.app-hero-8-area .home-8-shape-4 {
    position: absolute;
    bottom: 195px;
    left: 195px;
    z-index: -1;
  }
  .app-hero-area.app-hero-8-area .app-hero-content-8 .app-title {
    font-size: 70px;
    line-height: 80px;
    font-weight: 700;
    background: linear-gradient(90deg, #fb2d4e 25%, #4e88ff 75%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .app-hero-area.app-hero-8-area .app-hero-content-8 .app-title {
      font-size: 56px;
      line-height: 66px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-hero-area.app-hero-8-area .app-hero-content-8 .app-title {
      font-size: 42px;
      line-height: 66px;
    }
  }
  @media (max-width: 767px) {
    .app-hero-area.app-hero-8-area .app-hero-content-8 .app-title {
      font-size: 32px;
      line-height: 44px;
    }
  }
  
  .app-hero-thumb-6 .thumb {
    position: relative;
    z-index: 10;
  }
  .app-hero-thumb-6 .thumb .back-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: -1;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-hero-content {
      margin-bottom: 50px;
      text-align: center;
    }
  }
  @media (max-width: 767px) {
    .app-hero-content {
      margin-bottom: 50px;
      text-align: center;
    }
  }
  .app-hero-content > span {
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 500;
  }
  .app-hero-content .app-title {
    font-size: 60px;
    line-height: 70px;
    margin-top: 10px;
    margin-bottom: 22px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .app-hero-content .app-title {
      font-size: 48px;
      line-height: 58px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-hero-content .app-title {
      font-size: 48px;
      line-height: 58px;
    }
  }
  @media (max-width: 767px) {
    .app-hero-content .app-title {
      font-size: 28px;
      line-height: 38px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .app-hero-content .app-title {
      font-size: 36px;
      line-height: 46px;
    }
  }
  .app-hero-content p {
    font-size: 18px;
    line-height: 28px;
  }
  @media (max-width: 767px) {
    .app-hero-content p {
      font-size: 15px;
      line-height: 26px;
    }
  }
  .app-hero-content ul {
    margin: 41px 0 0;
    padding: 0;
    list-style-type: none;
  }
  .app-hero-content ul li {
    display: inline-block;
    margin-right: 8px;
  }
  @media (max-width: 767px) {
    .app-hero-content ul li {
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .app-hero-content ul li {
      margin-bottom: 0px;
    }
  }
  .app-hero-content ul li a {
    background: #0e1133;
    color: #fff;
    line-height: 40px;
    padding: 0 23px;
    border-radius: 6px;
    border: 2px solid #0e1133;
  }
  .app-hero-content ul li a i {
    padding-right: 6px;
  }
  .app-hero-content ul li a:hover {
    background: transparent;
    color: #0e1133;
  }
  .app-hero-content ul li a.item-2 {
    background: transparent;
    color: #0e1133;
  }
  .app-hero-content ul li a.item-2:hover {
    background: #0e1133;
    color: #fff;
  }
  .app-hero-content.app-hero-content-4 {
    padding-right: 40px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .app-hero-content.app-hero-content-4 {
      padding-right: 0;
    }
  }
  @media (max-width: 767px) {
    .app-hero-content.app-hero-content-4 {
      padding-right: 0;
    }
  }
  .app-hero-content.app-hero-content-4 > span {
    color: #801f82;
  }
  .app-hero-content.app-hero-content-4 .app-title {
    font-size: 70px;
    font-weight: 700;
    line-height: 80px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .app-hero-content.app-hero-content-4 .app-title {
      font-size: 60px;
      line-height: 70px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-hero-content.app-hero-content-4 .app-title {
      font-size: 50px;
      line-height: 60px;
    }
  }
  @media (max-width: 767px) {
    .app-hero-content.app-hero-content-4 .app-title {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .app-hero-content.app-hero-content-4 .app-title {
      font-size: 40px;
      line-height: 50px;
    }
  }
  .app-hero-content.app-hero-content-4 a.main-btn {
    border: 2px solid #801f82;
    color: #801f82;
    background: #fff;
    line-height: 55px;
    padding: 0 40px;
    font-size: 16px;
    margin-top: 40px;
  }
  .app-hero-content.app-hero-content-4 a.main-btn:hover {
    background: #801f82;
    color: #fff;
  }
  .app-hero-content.app-hero-content-6 > span {
    color: #9b2cfa;
  }
  .app-hero-content.app-hero-content-6 .app-title {
    font-weight: 700;
    font-size: 70px;
    line-height: 80px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .app-hero-content.app-hero-content-6 .app-title {
      font-size: 60px;
      line-height: 70px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-hero-content.app-hero-content-6 .app-title {
      font-size: 50px;
      line-height: 60px;
    }
  }
  @media (max-width: 767px) {
    .app-hero-content.app-hero-content-6 .app-title {
      font-size: 36px;
      line-height: 46px;
    }
  }
  .app-hero-content.app-hero-content-6 ul li a {
    background: #9b2cfa;
    border-color: #9b2cfa;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .app-hero-content.app-hero-content-6 ul li a span {
    line-height: 20px;
  }
  .app-hero-content.app-hero-content-6 ul li a span span {
    font-size: 16px;
    font-weight: 700;
    display: block;
    line-height: 20px;
  }
  .app-hero-content.app-hero-content-6 ul li a i {
    font-size: 30px;
  }
  .app-hero-content.app-hero-content-6 ul li a:hover {
    background: #fff;
    color: #0e1133;
    border-color: #fff;
  }
  .app-hero-content.app-hero-content-6 ul li a.item-2 {
    background: #fff;
    border-color: #fff;
  }
  .app-hero-content.app-hero-content-6 ul li a.item-2:hover {
    background: #9b2cfa;
    border-color: #9b2cfa;
    color: #fff;
  }
  .app-hero-content.app-hero-content-6.app-hero-content-7 .app-title {
    color: #fff;
  }
  .app-hero-content.app-hero-content-6.app-hero-content-7 p {
    color: #fff;
    opacity: 0.5;
  }
  .app-hero-content.app-hero-content-6.app-hero-content-7 ul li a {
    background: #fff;
    color: #0e1133;
    border-color: #fff;
  }
  .app-hero-content.app-hero-content-6.app-hero-content-7 ul li a:hover {
    background: transparent;
    border-color: #54386a;
    color: #fff;
  }
  .app-hero-content.app-hero-content-6.app-hero-content-7 ul li a.item-2 {
    background: transparent;
    color: #fff;
    border-color: #54386a;
  }
  .app-hero-content.app-hero-content-6.app-hero-content-7
    ul
    li
    a.item-2:hover {
    border-color: #fff;
    background: #fff;
    color: #0e1133;
  }
  
  .app-hero-thumb {
    position: relative;
    z-index: 10;
    text-align: center;
  }
  .app-hero-thumb .thumb-2 {
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 30px 70px 0px rgba(68, 1, 15, 0.2);
  }
  .app-hero-thumb::before {

    position: absolute;
    left: 50%;
    top: 55%;
    height: 480px;
    width: 480px;
    transform: translate(-50%, -50%);
    background: #f2f1f1;
    border-radius: 50%;
    z-index: -10;
  }
  .app-hero-thumb.app-hero-thumb-4::before {
    height: 160px;
    width: 160px;
    top: 200px;
    left: 70px;
    background: #801f82;
    transform: translate(0%, 0%);
  }
  .app-hero-thumb.app-hero-thumb-4 .hero-dot {
    position: absolute;
    right: 70px;
    bottom: 115px;
    z-index: -1;
  }
  
  .app-hero-area-2 {
    padding-top: 290px;
    padding-bottom: 170px;
    background: transparent;
    position: relative;
    overflow: hidden;
    z-index: 10;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .app-hero-area-2 {
      padding-top: 190px;
      padding-bottom: 120px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-hero-area-2 {
      padding-top: 190px;
      padding-bottom: 120px;
    }
  }
  @media (max-width: 767px) {
    .app-hero-area-2 {
      padding-top: 190px;
      padding-bottom: 150px;
    }
  }
  .app-hero-area-2 .app-hero-thumb-3 {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 48%;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .app-hero-area-2 .app-hero-thumb-3 {
      width: 60%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-hero-area-2 .app-hero-thumb-3 {
      width: 40%;
    }
  }
  @media (max-width: 767px) {
    .app-hero-area-2 .app-hero-thumb-3 {
      width: 36%;
    }
  }
  .app-hero-area-2 .hero-shape-1 {
    position: absolute;
    top: 250px;
    left: 130px;
    animation: linear 20s animationFramesOne infinite;
    z-index: -1;
  }
  .app-hero-area-2 .hero-shape-2 {
    position: absolute;
    bottom: 290px;
    left: 40px;
    animation: linear 20s animationFramesFour infinite;
    z-index: -1;
  }
  .app-hero-area-2 .hero-shape-3 {
    position: absolute;
    top: 480px;
    right: 240px;
    animation: linear 20s animationFramesFive infinite;
    z-index: -1;
  }
  .app-hero-area-2 .hero-shape-4 {
    position: absolute;
    top: 210px;
    right: 0px;
    animation: linear 20s animationFramesThree infinite;
    z-index: -1;
  }
  
  .app-hero-content-2 > span {
    font-size: 16px;
    font-weight: 500;
    color: #ff3e66;
    position: relative;
  }
  .app-hero-content-2 > span::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 130px;
    height: 70px;
    background-image: url(../../../assets/images/hero-line.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .app-hero-content-2 .app-title {
    font-size: 64px;
    line-height: 70px;
    font-weight: 400;
    margin-top: 15px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .app-hero-content-2 .app-title {
      font-size: 50px;
      line-height: 50px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-hero-content-2 .app-title {
      font-size: 50px;
      line-height: 60px;
    }
  }
  @media (max-width: 767px) {
    .app-hero-content-2 .app-title {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .app-hero-content-2 .app-title {
      font-size: 40px;
      line-height: 50px;
    }
  }
  .app-hero-content-2 .app-title span {
    font-weight: 900;
  }
  .app-hero-content-2 p {
    font-size: 18px;
    color: #0e1133;
    margin-top: 22px;
  }
  .app-hero-content-2 .input-box {
    position: relative;
    margin-top: 40px;
    width: 400px;
  }
  @media (max-width: 767px) {
    .app-hero-content-2 .input-box {
      width: 100%;
    }
  }
  .app-hero-content-2 .input-box > i {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    color: #c3c3cc;
  }
  .app-hero-content-2 .input-box input {
    width: 100%;
    height: 60px;
    border-radius: 40px;
    border: 0;
    border-left: 3px solid #ff3e66;
    padding-left: 55px;
    box-shadow: -3px 0px 0px 0px rgba(255, 62, 102, 0.004),
      0px 30px 30px 0px rgba(14, 17, 51, 0.1);
  }
  .app-hero-content-2 .input-box button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    background: #fff;
    border-radius: 50%;
    border: 0;
    color: #ff3e66;
  }
  .app-hero-content-2 .hero-users {
    display: flex;
    align-items: center;
    margin-top: 40px;
  }
  .app-hero-content-2 .hero-users > span {
    margin-left: 20px;
    font-size: 26px;
    font-weight: 900;
    color: #0e1133;
    width: 60px;
    display: inline-block;
    line-height: 16px;
  }
  .app-hero-content-2 .hero-users > span span {
    font-size: 13px;
    font-weight: 500;
    color: #505056;
    display: inline-block;
    line-height: 13px;
  }
  
  .app-page-title-item {
    margin-top: 80px;
    position: relative;
  }
  .app-page-title-item .thumb {
    position: absolute;
    right: 0;
    bottom: -100px;
    width: 160px;
  }
  @media (max-width: 767px) {
    .app-page-title-item .thumb {
      display: none;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .app-page-title-item .thumb {
      display: block;
    }
  }
  .app-page-title-item span {
    color: #fff;
    font-weight: 500;
    opacity: 0.7;
    padding-bottom: 14px;
  }
  .app-page-title-item .title {
    color: #fff;
    font-size: 44px;
    font-weight: 700;
  }
  @media (max-width: 767px) {
    .app-page-title-item .title {
      font-size: 26px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .app-page-title-item .title {
      font-size: 32px;
    }
  }
  .app-page-title-item nav {
    display: inline-block;
    margin-top: 20px;
  }
  .app-page-title-item nav ol {
    background: transparent;
    padding: 0;
  }
  .app-page-title-item nav ol li a {
    color: #fff;
    opacity: 0.8;
  }
  .app-page-title-item nav ol li.active {
    color: #fff;
    opacity: 0.8;
  }